import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/svg/logo2.svg';
import Cookies from 'universal-cookie';
import { useAppDispatch, useAppSelector } from '../libs/hooks';
import { AuthConstant } from '../constants/authConstant';
import { useEffect, useRef, useState } from 'react';
import { getUserInfo } from '../libs/reducers/userSlice';
import axios from 'axios';
import { HeadersUtil } from '../utils/headersUtil';
import { RoleConstant } from '../constants/roleConstant';

export default function Header() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const _token = params.get('access_token');
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const isLogin = useAppSelector((state) => state.userInfo.isLogin);
  const [showMenuMobile, setShowMenuMobile] = useState<any>(false);
  const menuRef = useRef<any>(null);
  const [showDropdownService, setShowDropdownService] = useState<any>(false);
  const [showDropdownInfo, setShowDropdownInfo] = useState<any>(false);

  useEffect(() => {
    if (_token) {
      axios
        .get(process.env.REACT_APP_COMMON_SERVICE_URL + '/api/role/getRolesByUser.exclude', {
          headers: HeadersUtil.getHeadersAuth()
        })
        .then(async (resp) => {
          const isAdmin = resp.data.responseData.some((role: any) => role.roleId === RoleConstant.ADMIN_USER);
          if (isAdmin) {
            window.location.href = process.env.REACT_APP_ADMIN_URL || '';
          }
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const login = () => {
    let domain = window.location.origin;
    var url = process.env.REACT_APP_AUTH_URL + '/login?redirect_uri=' + domain || '';
    window.location.href = url;
  };

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove(AuthConstant.ACCESS_TOKEN, {
      path: '/',
      domain: 'dlkhcn.io.vn'
    });
    // navigate("/");
    window.location.href = '/';
  };

  const onClickMenuMobile = () => {
    setShowMenuMobile(!showMenuMobile);
  };

  useEffect(() => {
    // Lắng nghe sự kiện click toàn bộ tài liệu
    document.addEventListener('mousedown', handleClick);

    // Dọn dẹp sự kiện khi component bị unmount
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && event.target.className !== 'button-menu-toggle') {
      setShowMenuMobile(false);
    }
  };

  return (
    <>
      <div className={'header' + (showMenuMobile ? ' header-menu-show' : '')}>
        <div className="container-fluid">
          <div className="header-logo">
            <a href="/">
              <img src={logo} style={{ height: '50px' }} alt="" />
            </a>
          </div>
          <div className="header-menu nav-link-hover-line ml-0" ref={menuRef}>
            <ul className="nav">
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/trang-chu' ? ' active' : '')} to="/trang-chu">
                  Trang chủ
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/quy-trinh-dinh-gia' ? ' active' : '')} to="/quy-trinh-dinh-gia">
                  Quy trình định giá
                </Link>
              </li>
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/ve-chung-toi' ? ' active' : '')} to="/ve-chung-toi">
                  Về NSTD
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="javascript:void(0)">
                  Dịch vụ
                </a>
                <ul className={"nav-dropdown" + (showDropdownService ? " show" : "")}>
                  <li className="nav-dropdown-item">
                    <a className="nav-dropdown-link" href="https://congthongtin.dlkhcn.io.vn" target="_blank">
                      Cổng thông tin
                    </a>
                  </li>
                  <li className="nav-dropdown-item">
                    <a className="nav-dropdown-link" href="https://baoho.dlkhcn.io.vn" target="_blank">
                      Bảo hộ
                    </a>
                  </li>
                  <li className="nav-dropdown-item">
                    <a className="nav-dropdown-link" href="https://chuyengiao.dlkhcn.io.vn" target="_blank">
                      Chuyển nhượng
                    </a>
                  </li>
                </ul>
                <div className="nav-dropdown-toggle" onClick={() => setShowDropdownService(!showDropdownService)}>
                  <i className="bi bi-chevron-down"></i>
                </div>
              </li>
              <li className="nav-item">
                <Link className={'nav-link' + (location.pathname === '/lien-he' ? ' active' : '')} to="/lien-he">
                  Liên hệ
                </Link>
              </li>
            </ul>
          </div>
          <div className="header-extra">
            {!isLogin ? (
              <div className="button button-sm button-gradient-3 button-rounded button-hover-slide" onClick={login}>
                <span data-text="Đăng nhập">Đăng nhập</span>
              </div>
            ) : (
              <>
                <div className="header-menu nav-link-hover-line">
                  <ul className="nav">
                    <li className="nav-item">
                      <i className="bi bi-person-circle fs-4 me-2"></i>
                      <a className="nav-link" href="javascript:void(0)">
                        {userInfo.email}
                      </a>
                      <ul className="nav-dropdown">
                        <li className="nav-dropdown-item">
                          <Link className="nav-dropdown-link" to="">
                            Thông tin cá nhân
                          </Link>
                        </li>
                        <li className="nav-dropdown-item">
                          <Link className="nav-dropdown-link" to="/sang-che-cua-toi">
                            Sáng chế của tôi
                          </Link>
                        </li>
                        <li className="nav-dropdown-item">
                          <a className="nav-dropdown-link" onClick={logout}>
                            Đăng xuất
                          </a>
                        </li>
                      </ul>
                      <div className="nav-dropdown-toggle" onClick={() => setShowDropdownInfo(!showDropdownInfo)}>
                        <i className="bi bi-chevron-down"></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <button className={'mobile-menu-toggle' + (showMenuMobile ? ' toggle-close' : '')} onClick={onClickMenuMobile}>
            <span className="button-menu-toggle"></span>
          </button>
        </div>
      </div>
    </>
  );
}
